export const SET_QUIZ_SETS = 'SET_QUIZ_SETS'
export const UPDATE_QUIZ_SETS = 'UPDATE_QUIZ_SETS'
export const SET_TOPICS = 'SET_TOPICS'
export const ADD_TOPIC = 'ADD_TOPIC'
export const SET_OHM_TEACHER_BATCH = 'SET_OHM_TEACHER_BATCH'
export const ADD_QUIZ_SET = 'ADD_QUIZ_SET'
export const SET_QUIZ_RESULTS = 'SET_QUIZ_RESULTS'
export const SET_SUBMIT_FEEDBACK_STATUS = 'SET_SUBMIT_FEEDBACK_STATUS'
export const SET_QUIZES = 'SET_QUIZES'
export const EMPTY_QUIZES = 'EMPTY_QUIZES'
