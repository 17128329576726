import 'date-time-format-timezone' // polyfill for older browser
import Dayjs from 'dayjs'
import 'dayjs/plugin/timezone'

const timezone = require('dayjs/plugin/timezone')
const utc = require('dayjs/plugin/utc')

Dayjs.extend(utc)
Dayjs.extend(timezone)

Dayjs.tz.setDefault('Asia/Kolkata')

export default Dayjs
