import styled from 'styled-components'

interface AppBodyProps {
  full?: boolean
}

interface PageContainerProps {
  full?: boolean
}

export const AppBody = styled.div<AppBodyProps>`
  margin-top: ${(p) => (p.full ? '0' : '0')};
`

export const PageContainer = styled.div<PageContainerProps>`
  margin-left: ${(p) => (p.full ? '0' : '13rem')};

  @media (max-width: 500px) {
    margin-left: 0;
    margin-bottom: 75px;
  }
  @media (min-width: 481px) and (max-width: 920px) and (orientation: landscape) {
    margin-left: 0;
    margin-bottom: 85px;
  }
`
