import { extractURLQueryParams, getRedirectUrl } from '@helpers/urlHelpers'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import RedirectComponent from '@components/pages/RedirectComponent/RedirectComponent'

export interface IPrivateRouteProps extends RouteProps {
  authenticated: boolean
  getRedirectPath: Function
}

/**
 * if user is unauthenticated then redirect to "/login" else show the route
 *
 * keeps the user from accessing any route without signing in
 *
 * @param props
 */
const PrivateRoute: React.FC<IPrivateRouteProps> = (props) => {
  const data = getRedirectUrl(props.authenticated)
  const queries = extractURLQueryParams()

  const { internal, url } = data
  if (internal === false && url) {
    return (
      <Route
        component={() => <RedirectComponent url={url} />}
        render={undefined}
      />
    )
  } else {
    if (internal && url && window.location.pathname === props.path) {
      return <Redirect to={url} />
    }
  }

  return props.authenticated ? (
    <Route exact {...props} component={props.component} render={undefined} />
  ) : (
    <Redirect to={props.getRedirectPath(queries)} />
  )
}

export default PrivateRoute
