import React from 'react'

const LoginPage = React.lazy(() => import('@components/pages/Auth/LoginPage'))
const SignupPage = React.lazy(() => import('@components/pages/Auth/SignupPage'))
const Allbatches = React.lazy(
  () => import('@components/pages/Batch/AllBatches/AllBatches')
)
const SingleBatch = React.lazy(
  () => import('@components/pages/Batch/SingleBatch/SingleBatch')
)
const DashboardPage = React.lazy(
  () => import('@components/pages/Dashboard/Dashboard')
)
const DemosPage = React.lazy(() => import('@components/pages/Demos/Demos'))
const AvatarPage = React.lazy(() => import('@components/pages/Avatar/avatar'))

const LobbyNewPage = React.lazy(
  () => import('@components/pages/LobbyNew/LobbyNew')
)
const PaymentsPage = React.lazy(
  () => import('@components/pages/Payments/Payments')
)
const ReportCardsPage = React.lazy(
  () => import('@components/pages/ReportCards/ReportCards')
)
const QuizPage = React.lazy(() => import('@components/pages/Quiz/Quiz'))

const MyDrivePage = React.lazy(
  () => import('@components/pages/MyDrive/MyDrive')
)

const QuestionBankPage = React.lazy(
  () => import('@components/pages/QuestionBank/QuestionBankPage')
)

const LogoutPage = React.lazy(() => import('@components/pages/Logout'))

const NotFoundPage = React.lazy(
  () => import('@components/pages/NotFound/NotFound')
)

export default {
  LoginPage,
  SignupPage,
  Allbatches,
  SingleBatch,
  DashboardPage,
  DemosPage,
  AvatarPage,
  LobbyNewPage,
  PaymentsPage,
  ReportCardsPage,
  QuizPage,
  MyDrivePage,
  LogoutPage,
  NotFoundPage,
  QuestionBankPage
}
