import { EVENTS_SECTION_ID, SOCIAL_SECTION_ID } from '@constants/socialSection'
import * as actionTypes from '../actionTypes/myLobby'

const initialState = {
  mentorDetails: {},
  activeUsers: [],
  usersLog: [],
  events: [],
  sections: [],
  summary: {
    isLoaded: false,
    data: {
      schedule_today: [],
      next_upcoming: [],
      quote: '',
      author: ''
    }
  },
  registeredEvents: [],
  allEvents: [],
  isEventOnly: false
}

const setJourneyMentor = (state: any, payload: any) => {
  return {
    ...state,
    mentorDetails: payload
  }
}

const setActiveUsers = (state: any, payload: any) => {
  return {
    ...state,
    activeUsers: payload
  }
}

const setUsersLog = (state: any, payload: any) => {
  return {
    ...state,
    usersLog: payload
  }
}

const setEvents = (state: any, payload: any) => {
  return {
    ...state,
    events: payload
  }
}

const setIsEventOnly = (state: any, payload: any) => {
  return {
    ...state,
    isEventOnly: payload
  }
}

const setSections = (state: any, payload: any) => {
  const landingSection = {
    uuid: 'e20b4222-3b88-4317-83ed-d05723f8e1d4',
    header: 'home',
    type: 'Landing'
  }

  const modifiedPayload = payload.map((item: any) => {
    return {
      ...item,
      label: item.header.split(' ')[0]
    }
  })
  const eventsSection = {
    uuid: EVENTS_SECTION_ID,
    header: 'events',
    type: 'eventssection'
  }

  const socialSection = {
    uuid: SOCIAL_SECTION_ID,
    header: 'social',
    type: 'social'
  }
  let sectionsOrder = [landingSection]
  const otherDoors: any = []
  if (state.isEventOnly) {
    modifiedPayload.forEach((x: any) => {
      if (x.type === 'Event') {
        sectionsOrder.push(x)
      } else {
        otherDoors.push(x)
      }
    })

    sectionsOrder.push(eventsSection)
    sectionsOrder.push(...otherDoors)
  } else {
    sectionsOrder = [
      landingSection,
      socialSection,
      ...modifiedPayload,
      eventsSection
    ]
  }

  return {
    ...state,
    sections: sectionsOrder
  }
}

const setSummary = (state: any, payload: any) => {
  return {
    ...state,
    summary: {
      isLoaded: true,
      data: payload
    }
  }
}

const setRegisteredEvents = (state: any, payload: any) => {
  return {
    ...state,
    registeredEvents: payload
  }
}

const setAllEvents = (state: any, payload: any) => {
  return {
    ...state,
    allEvents: payload
  }
}

const lobbyReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_USERS:
      return setActiveUsers(state, action.payload)
    case actionTypes.GET_USERS_LOG:
      return setUsersLog(state, action.payload)
    case actionTypes.GET_EVENTS:
      return setEvents(state, action.payload)
    case actionTypes.GET_SECTIONS:
      return setSections(state, action.payload)
    case actionTypes.GET_SUMMARY:
      return setSummary(state, action.payload)
    case actionTypes.GET_JOURNEY_MENTOR:
      return setJourneyMentor(state, action.payload)
    case actionTypes.GET_REGISTERED_EVENTS:
      return setRegisteredEvents(state, action.payload)
    case actionTypes.GET_ALL_EVENTS:
      return setAllEvents(state, action.payload)
    case actionTypes.GET_IS_EVENT_ONLY:
      return setIsEventOnly(state, action.payload)
    default:
      return state
  }
}

export default lobbyReducer
