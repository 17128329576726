export const GET_TEACHER_DUE_PAYMENTS_START = 'GET_TEACHER_DUE_PAYMENTS_START'
export const GET_TEACHER_DUE_PAYMENTS_SUCCESS =
  'GET_TEACHER_DUE_PAYMENTS_SUCCESS'
export const GET_TEACHER_DUE_PAYMENTS_FAILED = 'GET_TEACHER_DUE_PAYMENTS_FAILED'

export const GET_TEACHER_PAID_PAYMENTS_START = 'GET_TEACHER_PAID_PAYMENTS_START'
export const GET_TEACHER_PAID_PAYMENTS_SUCCESS =
  'GET_TEACHER_PAID_PAYMENTS_SUCCESS'
export const GET_TEACHER_PAID_PAYMENTS_FAILED =
  'GET_TEACHER_PAID_PAYMENTS_FAILED'

export const GET_TEACHER_DISPATCHED_PAYMENTS_START =
  'GET_TEACHER_DISPATCHED_PAYMENTS_START'
export const GET_TEACHER_DISPATCHED_PAYMENTS_SUCCESS =
  'GET_TEACHER_DISPATCHED_PAYMENTS_SUCCESS'
export const GET_TEACHER_DISPATCHED_PAYMENTS_FAILED =
  'GET_TEACHER_DISPATCHED_PAYMENTS_FAILED'

export const RESET_PAYMENTS = 'RESET_PAYMENTS'

export const GET_STUDENT_DUE_PAYMENTS_START = 'GET_STUDENT_DUE_PAYMENTS_START'
export const GET_STUDENT_DUE_PAYMENTS_SUCCESS =
  'GET_STUDENT_DUE_PAYMENTS_SUCCESS'
export const GET_STUDENT_DUE_PAYMENTS_FAILED = 'GET_STUDENT_DUE_PAYMENTS_FAILED'

export const GET_STUDENT_PAST_PAYMENTS_START = 'GET_STUDENT_PAST_PAYMENTS_START'
export const GET_STUDENT_PAST_PAYMENTS_SUCCESS =
  'GET_STUDENT_PAST_PAYMENTS_SUCCESS'
export const GET_STUDENT_PAST_PAYMENTS_FAILED =
  'GET_STUDENT_PAST_PAYMENTS_FAILED'
