import { ohrAxios } from '@services/httpInstance/axios'
import getUrls from '@utils/getUrls'
import { isEmpty } from 'lodash'

import sendNotification from '../../helpers/showNotification'
import { avatarAPI } from '../../services/networkCalls/myAvatar/avatar'
import * as avatarActions from '../actions/myAvatarActions'
import * as userActions from '../actions/userAuthentication'

const {
  AVATAR_COMPONENTS_URL,
  USER_AVATAR_URL,
  AVATAR_RESET_URL,
  MODIFY_AVATAR_URL,
  AVATAR_COMPLETE
} = getUrls()

export const getMyAvatarDetails = () => (dispatch: any) => {
  ohrAxios
    .get(USER_AVATAR_URL)
    .then((res: any) => {
      if (!isEmpty(res.data.results)) {
        const svgElement = `<svg width="1000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 503 511" xml:space="preserve" preserveAspectRatio="xMidYMid meet">${res.data.results[0].rendered_svg}</svg>`
        const imageblob = new Blob([svgElement], { type: 'image/svg+xml' })
        const url = URL.createObjectURL(imageblob)
        dispatch(userActions.setMyAvatar(url))
        dispatch(avatarActions.getMyAvatarDetails(res.data.results))
      }
    })
    .catch((err: any) => {
      sendNotification('failed', 'getMyAvatarDetails', 'danger')
    })
}

export const resetAvatar = () => (dispatch: any) => {
  ohrAxios
    .get(AVATAR_RESET_URL)
    .then((res: any) => {
      getMyAvatarDetails()(dispatch)
    })
    .catch((err: any) => {
      sendNotification('failed', 'resetAvatar', 'danger')
    })
}

export const modifyMyAvatar = (uuid: any, e: any) => (dispatch: any) => {
  const type = e.type + '_path'
  ohrAxios
    .patch(`${MODIFY_AVATAR_URL}/${uuid}/`, {
      [type]: e.uuid
    })
    .then((res: any) => {
      dispatch(avatarActions.modifyMyAvatarDetails(e))
    })
    .catch((err: any) => {
      sendNotification('failed', 'modifyMyAvatar', 'danger')
    })
}

export const getAllAvatarDetails = () => (dispatch: any) => {
  avatarAPI(AVATAR_COMPONENTS_URL, { limit: 100 })
    .then((res: any) => {
      dispatch(avatarActions.getAllAvatarComponentDetails(res.results))
    })
    .catch((err: any) => {
      sendNotification('failed', 'getAllAvatarDetails', 'danger')
    })
}

export const avatarCreationComplete = () => {
  ohrAxios.post(AVATAR_COMPLETE).catch((err: any) => {
    sendNotification('failed', 'avatarCreationComplete', 'danger')
  })
}
