export const styles = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'center',
  alignItems: 'center'
}

export const generateDefaultOptions = (animationData) => ({
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
})
