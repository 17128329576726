import navigationPaths from '@routes/RoutesPaths'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

// hooks and services
import * as UserProfileStyles from './styles'

// components, styles and UI elements
const logoutIcon = require('@assets/icons/LogoutIcon.svg')
const profileIcon = require('@assets/icons/UserIconSolid2.svg')

// types
export interface UserProfileProps {}

/**
 * Displays a card which contains info about user
 * and also the logout button
 */
const UserProfile: React.FunctionComponent<UserProfileProps> = () => {
  // external hooks
  const { userInfo, type } = useSelector((state: any) => state.user)

  const history = useHistory()

  const setUpMyAvatar = () => {
    if (userInfo.avatar) {
      const myAvatarImg = <img src={userInfo.avatar} alt="avatar" />
      return myAvatarImg
    }
    return <img src={profileIcon} alt="profile" width="70" height="70" />
  }

  const logout = () => {
    history.push({
      pathname: navigationPaths.logout
    })
  }

  return (
    <UserProfileStyles.Container>
      <UserProfileStyles.PictureContainer>
        <UserProfileStyles.MyAvatar type={type}>
          {setUpMyAvatar()}
        </UserProfileStyles.MyAvatar>
      </UserProfileStyles.PictureContainer>
      <UserProfileStyles.Info>
        <UserProfileStyles.Name>{userInfo.full_name}</UserProfileStyles.Name>
        <UserProfileStyles.Phone>{userInfo.user_name}</UserProfileStyles.Phone>
      </UserProfileStyles.Info>
      <UserProfileStyles.LogoutContainer>
        <UserProfileStyles.LogoutText>logout</UserProfileStyles.LogoutText>
        <UserProfileStyles.LogoutIcon
          onClick={logout}
          src={logoutIcon}
          alt="logout"
        />
      </UserProfileStyles.LogoutContainer>
    </UserProfileStyles.Container>
  )
}

export default UserProfile
