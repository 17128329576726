import * as actionTypes from '../actionTypes/myschedule'

const initialState = {
  schedule: {
    fetchingScheduleForToday: false,
    scheduleForToday: [],
    fetchingScheduleForWeek: false,
    scheduleForWeek: [],
    colorMapping: {},
    error: null
  }
}

const setSchedulesOfTodayStart = (state: any) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      fetchingScheduleForToday: true
    }
  }
}

const setSchedulesOfTodaySuccess = (state: any, payload: any) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      scheduleForToday: payload,
      fetchingScheduleForToday: false
    }
  }
}

const setSchedulesOfTodayFailed = (state: any, payload: any) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      error: payload,
      fetchingScheduleForToday: false
    }
  }
}
const setSchedulesOfWeekStart = (state: any) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      fetchingScheduleForWeek: true
    }
  }
}
const setSchedulesOfWeekSuccess = (state: any, payload: any) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      scheduleForWeek: payload,
      fetchingScheduleForWeek: false
    }
  }
}
const setSchedulesOfWeekFailed = (state: any, payload: any) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      error: payload,
      fetchingScheduleForWeek: false
    }
  }
}
/*setting uuid:color to the map*/
const setColor = (state: any, payload: Array<string | undefined>) => {
  return {
    ...state,
    schedule: {
      ...state.schedule,
      colorMapping: {
        ...state.schedule.colorMapping,
        [payload[0] as any]: payload[1]
      }
    }
  }
}

const resetState = () => initialState

const scheduleReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_SCHEDULE_FOR_TODAY_START:
      return setSchedulesOfTodayStart(state)
    case actionTypes.GET_SCHEDULE_FOR_TODAY_SUCCESS:
      return setSchedulesOfTodaySuccess(state, action.payload)
    case actionTypes.GET_SCHEDULE_FOR_TODAY_FAILED:
      return setSchedulesOfTodayFailed(state, action.payload)
    case actionTypes.GET_SCHEDULE_FOR_WEEK_START:
      return setSchedulesOfWeekStart(state)
    case actionTypes.GET_SCHEDULE_FOR_WEEK_SUCCESS:
      return setSchedulesOfWeekSuccess(state, action.payload)
    case actionTypes.GET_SCHEDULE_FOR_WEEK_FAILED:
      return setSchedulesOfWeekFailed(state, action.payload)
    case actionTypes.RESET_SCHEDULE:
      return resetState()
    case actionTypes.SET_COLOR_MAPPING:
      return setColor(state, action.payload)
    default:
      return state
  }
}

export default scheduleReducer
