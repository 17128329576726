import styled from 'styled-components'

const landingSectionBackground = require('@assets/Illustrations/SVGs/landing_top.svg')

export const LoadingBackground = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url(${landingSectionBackground}) top left no-repeat;
  background-color: #fff;
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LoadingText = styled.div`
  font-family: Avenir Next Rounded Std;
`
