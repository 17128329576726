import sanitizeHTML from 'sanitize-html'

export const parseData = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  }
}

export const isNotUndefined = (value) => {
  return value !== undefined
}

export const isNumber = (value) => {
  return typeof value === 'number'
}

export const isString = (value) => {
  return typeof value === 'string'
}

export const convertHTMLEntity = (text) => {
  const span = document.createElement('span')
  return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
    span.innerHTML = entity
    return span.innerText
  })
}

export const scrollTop = () => {
  document.getElementById('cr-app').scrollTop = 0
}

export const convertDateFormat = (date) => {
  date = new Date(date)
  let month = date.getMonth() + 1
  let day = date.getDate()
  month = month < 10 ? `0${month}` : month
  day = day < 10 ? `0${day}` : day
  return `${date.getFullYear()}-${month}-${day}`
}

export const isEmpty = (item) => {
  if (typeof item === 'number') {
    return false
  }
  return item.trim() === ''
}

export const isEqual = (item1, item2) => {
  if (typeof item1 === 'number' && typeof item2 === 'number') {
    return item1 === item2
  }
  if (typeof item1 === 'string' && typeof item2 === 'string') {
    return item1.toLowerCase() === item2.toLowerCase()
  }
}

export const containsOnlyText = (item) => {
  const regex = /^[a-zA-Z ]*$/
  return regex.test(item)
}

export const containsOnlyNumber = (item) => {
  let regex = /^[0-9]*$/
  return regex.test(item)
}

export const isGreaterThan = (item1, item2) => {
  return item1 > item2
}

export const isGreaterThanOrEqualTo = (item1, item2) => {
  return item1 >= item2
}

export const isLessThan = (item1, item2) => {
  return item1 < item2
}

export const isLessThanOrEqualTo = (item1, item2) => {
  return item1 <= item2
}

export const getSum = (...item) => {
  return item.reduce((val, acc) => val + acc)
}

export const create_UUID = (name) => {
  var dt = new Date().getTime()
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0
    dt = Math.floor(dt / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
  return uuid.concat(name)
}

export const convertToTimestamp = (date) => {
  return new Date(date).getTime()
}

export const awsCredentials = (fileType) => {
  const config = {
    bucketName: 'ai-olympiad-prod',
    ContentType: fileType,
    region: 'ap-south-1',
    accessKeyId: 'AKIAI2GSCLJUNICM2K5A',
    secretAccessKey: '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'
  }
  return config
}

export const getUrlPathName = () => {
  return window.location.pathname
}

export const getLabel = (index) => {
  switch (index) {
    case 0:
      return 'a'
    case 1:
      return 'b'
    case 2:
      return 'c'
    case 3:
      return 'd'
    default:
      return ''
  }
}

export const sanitize = (htmlString) => {
  try {
    return sanitizeHTML(htmlString)
  } catch (error) {
    console.log('error in sanitizing html :- ', sanitize)
  }
}

export const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}
