import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

// components, styles and UI elements
import * as MenubarStyles from './styles'

import UserProfile from '../UserProfile/UserProfile'

const profileIcon = require('@assets/icons/UserIconSolid2.svg')
// types
export interface MenubarProps {}

/**
 * Displays a bar on top of every page
 */

const hiddenInUrls: string[] = ['/']

const Menubar: React.FunctionComponent<MenubarProps> = () => {
  const [userProfileVisible, setUserProfileVisible] = useState(false)
  const location = useLocation()
  const isHidden = hiddenInUrls.includes(location.pathname)
  const { type, userInfo } = useSelector((state: any) => state.user)
  const setUpMyAvatar = () => {
    if (userInfo.avatar) {
      const myAvatarImg = (
        <MenubarStyles.AvatarImage src={userInfo.avatar} alt="avatar" />
      )
      return myAvatarImg
    }
    return <MenubarStyles.AvatarImage src={profileIcon} alt="profile" />
  }

  const hiddenRoutes = ['/login', '/signup']

  return (
    <MenubarStyles.Wrapper hidden={isHidden}>
      {!hiddenRoutes.includes(location.pathname) && (
        <MenubarStyles.ProfileSection>
          <MenubarStyles.MyAvatar
            type={type}
            onClick={() => setUserProfileVisible(!userProfileVisible)}
          >
            {setUpMyAvatar()}
          </MenubarStyles.MyAvatar>
          {userProfileVisible ? <UserProfile /> : null}
        </MenubarStyles.ProfileSection>
      )}
    </MenubarStyles.Wrapper>
  )
}

export default Menubar
