import styled from 'styled-components'

interface UserTypeProps {
  type: string
}

export const Wrapper = styled.div`
  height: 3rem;
  background-color: transparent;
  padding: 0 0.5rem;
  position: absolute;
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  top: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    // padding: 0 0.8rem;
    // position: relative;
    width: 100vw;
    z-index: 10;
    justify-content: flex-end;
    // padding: 0;
  }
`

export const ProfileSection = styled.div`
  width: 2rem;
  margin-right: 3rem;
  margin-top: 1rem;
  height: 2rem;
  @media (max-width: 500px) {
    margin-right: 2.5rem;
    z-index: 10;
  }
`

export const UserIcon = styled.img`
  width: 100%;
  cursor: pointer;
`

export const MyAvatar = styled.div<UserTypeProps>`
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  z-index: 10;
  justify-content: center;
  background: ${(props) =>
    props.type === 'student' ? '#4c82d3' : 'var(--oh-v2-vl-3)'};
  cursor: pointer;
`
export const AvatarImage = styled.img`
  height: 45px;
  width: 45px;
`
