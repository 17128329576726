import { ohmAxios } from '@services/httpInstance/axios'
import getUrls from '@utils/getUrls'

import * as actions from '../actions/serverSide'

export const getServerTime = () => (dispatch: any) => {
  dispatch(actions.getServerTimeStart())
  const { SERVER_TIME } = getUrls()
  ohmAxios
    .get(SERVER_TIME)
    .then((data: any) => {
      dispatch(actions.getServerTimeSuccess(data.data?.currentDateTime))
    })
    .catch((err: any) => {
      dispatch(actions.getServerTimeFailed(err))
    })
}
