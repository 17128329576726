import { OhLobbyLoader } from '@components/common/LottieComponents'
import React from 'react'

import { LoadingBackground, LoadingContainer, LoadingText } from './styles'

const LobbyLoader = () => {
  return (
    <LoadingBackground>
      <LoadingContainer>
        <OhLobbyLoader />
        <LoadingText>hang on! loading your oh! lobby</LoadingText>
      </LoadingContainer>
    </LoadingBackground>
  )
}

export default LobbyLoader
