// import {
//   DO_NOT_ASK_FOR_AVATAR,
//   DO_NOT_ASK_FOR_LOBBY
// } from '@constants/permissionKeys'
// import { getCookie } from '@helpers/cookie'
import useIsMobileView from '@hooks/displaySize/useIsMobileView'
import { privateRoutes } from '@routes/RoutesConfig'
import navigationPaths from '@routes/RoutesPaths'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import * as SidebarStyles from './styles'

// types
export interface SidebarProps {}

const hiddenInUrls = [
  navigationPaths.home,
  navigationPaths.login,
  navigationPaths.signup
]

/**
 * Universal sidebar present on all pages except login
 */
const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const location = useLocation()
  const isMobile = useIsMobileView()

  const isHidden = hiddenInUrls.includes(location.pathname)
  // const { is_default } = useSelector((state: any) => state.avatar.myAvatar)
  const isEventOnly = useSelector((state: any) => state.lobby.isEventOnly)

  // FIXME: commenting this, please put more logic here why we are doing this ?
  // if (!isHidden) {
  //   if (
  //     isMobile &&
  //     [true, undefined].includes(is_default) &&
  //     !getCookie(DO_NOT_ASK_FOR_AVATAR)
  //   ) {
  //     isHidden = true
  //   } else {
  //     if (isMobile && !getCookie(DO_NOT_ASK_FOR_LOBBY)) {
  //       isHidden = true
  //     }
  //   }
  // }

  // gets the type of user Teacher/Student
  const type = useSelector((state: any) => state.user.type)

  return (
    <SidebarStyles.Wrapper hidden={isHidden}>
      {!isMobile && <SidebarStyles.Logo>oh. dashboard</SidebarStyles.Logo>}
      {privateRoutes
        .filter((route) =>
          !isEventOnly
            ? route?.sidebarVisibilty.includes(type)
            : route?.sidebarVisibilty.includes(type) &&
              type === 'student' &&
              ['my schedule', 'contact us'].includes(route?.sidebarName)
        )
        .map((route, index) => {
          let active = location.pathname === route.path
          if (
            location.pathname === '/attendance/details' &&
            route.path === '/attendance'
          ) {
            active = true
          }
          return route.component ? (
            <SidebarStyles.MenuItem
              key={index}
              to={route.path}
              hidden={route.hidden}
            >
              <SidebarStyles.MenuIcon
                active={active}
                src={require(`@assets/icons/${route.sidebarIcon}`)}
                alt={`l: ${location.pathname}, r: ${route.path}`}
              />
              <SidebarStyles.MenuName active={active}>
                {route.sidebarName}
              </SidebarStyles.MenuName>
            </SidebarStyles.MenuItem>
          ) : (
            <SidebarStyles.MenuItemAnchor
              key={index}
              href={route.path}
              hidden={route.hidden}
              target="_blank"
            >
              <SidebarStyles.MenuIcon
                active={location.pathname === route.path}
                src={require(`@assets/icons/${route.sidebarIcon}`)}
                alt={route.sidebarIcon}
              />
              <SidebarStyles.MenuName active={location.pathname === route.path}>
                {route.sidebarName}
              </SidebarStyles.MenuName>
            </SidebarStyles.MenuItemAnchor>
          )
        })}
      <SidebarStyles.TermsAndPrivacyPolicy>
        <SidebarStyles.PolicyLink
          href="https://openhouse.study/terms-of-service"
          target="_blank"
        >
          terms
        </SidebarStyles.PolicyLink>
        and
        <SidebarStyles.PolicyLink
          href="https://openhouse.study/privacy-policy"
          target="_blank"
        >
          privacy policy
        </SidebarStyles.PolicyLink>
      </SidebarStyles.TermsAndPrivacyPolicy>
    </SidebarStyles.Wrapper>
  )
}

export default Sidebar
