import { SERVER_DATE_TIME_FORMAT } from '@constants/dateTimeFormat'
import Dayjs from '@modules/Dayjs'

import * as actionTypes from '../actionTypes/serverSide'

const initialState = {
  fetchingServerTime: false,
  currentTime: null,
  currentTimeLoaded: false,
  error: null
}

const setServerTimeStart = (state: any) => {
  return {
    ...state,
    fetchingServerTime: true
  }
}
const setServerTimeSuccess = (state: any, payload: any) => {
  return {
    ...state,
    fetchingServerTime: false,
    currentTime: Dayjs(payload).format(SERVER_DATE_TIME_FORMAT),
    currentTimeLoaded: true
  }
}
const setServerTimeFailed = (state: any, payload: any) => {
  return {
    ...state,
    fetchingServerTime: false,
    error: payload,
    currentTimeLoaded: true
  }
}

const updateServerTime = (state: any) => {
  if (!state?.currentTime) {
    return state
  }

  return {
    ...state,
    currentTime: Dayjs(state.currentTime)
      .add(1, 'second')
      .format(SERVER_DATE_TIME_FORMAT)
  }
}

const serverSideReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_SERVER_TIME_START:
      return setServerTimeStart(state)
    case actionTypes.GET_SERVER_TIME_SUCCESS:
      return setServerTimeSuccess(state, action.payload)
    case actionTypes.GET_SERVER_TIME_FAILED:
      return setServerTimeFailed(state, action.payload)
    case actionTypes.UPDATE_SERVER_TIME:
      return updateServerTime(state)
    default:
      return state
  }
}

export default serverSideReducer
