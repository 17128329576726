import * as actionTypes from '../actionTypes/userAuthentication'

export const setAuth = (payload: any) => {
  return {
    type: actionTypes.SET_USER_AUTENTICATED,
    payload
  }
}

export const setUserInfo = (payload: any) => {
  return {
    type: actionTypes.SET_USER_INFO,
    payload
  }
}

export const setTokens = (payload: any) => {
  return {
    type: actionTypes.SET_USER_AUTH_TOKENS,
    payload
  }
}

export const setUserType = (payload: any) => {
  return {
    type: actionTypes.SET_USER_TYPE,
    payload
  }
}

export const setMyAvatar = (payload: any) => {
  return {
    type: actionTypes.SET_MY_AVATAR,
    payload
  }
}

export const logout = () => {
  return {
    type: actionTypes.PERFORM_LOGOUT
  }
}
