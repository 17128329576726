import { AppActionTypes } from '../actions/app'
import {
  INITIALIZE_SERVICE_WORKER,
  UPDATE_SERVICE_WORKER
} from '../actionTypes/app'

export interface AppState {
  serviceWorkerInitialized: boolean
  serviceWorkerUpdated: boolean
  serviceWorkerRegistration: {
    waiting: {
      postMessage: Function
      addEventListener: Function
    }
  } | null
}

const initialState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null
}

const appReducer = (
  state: AppState = initialState,
  action: AppActionTypes
): AppState => {
  switch (action.type) {
    case INITIALIZE_SERVICE_WORKER:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized
      }

    case UPDATE_SERVICE_WORKER:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload
      }

    default:
      return state
  }
}

export default appReducer
