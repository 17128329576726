import { NOTIFICATION_POSITIONS } from '@constants/notification'
import React from 'react'
import { Slide } from 'react-toastify'

import { NotificationComponent } from './styles'

const Notification = () => {
  return (
    <NotificationComponent
      transition={Slide}
      position={NOTIFICATION_POSITIONS.BOTTOM_LEFT}
      autoClose={3000}
      hideProgressBar
      newestOnTop
      closeOnClick
      closeButton={false}
      rtl={false}
      draggable={false}
      pauseOnHover
    />
  )
}

export default Notification
