import { extractURLQueryParams, getRedirectUrl } from '@helpers/urlHelpers'
import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import RedirectComponent from '@components/pages/RedirectComponent/RedirectComponent'

export interface ILoginRouteProps extends RouteProps {
  authenticated: boolean
  getRedirectPath: Function
}

/**
 * if user is authenticated then redirect to "/" else show LoginRoute
 *
 * This route is created to ensure that when the user is already
 * authenticated then they should not be able to see login page again
 *
 * @param props
 */
const LoginRoute: React.FC<ILoginRouteProps> = (props) => {
  const data = getRedirectUrl(props.authenticated)
  const queries = extractURLQueryParams()

  const { internal, url } = data
  if (internal === false && url) {
    return (
      <Route
        component={() => <RedirectComponent url={url} />}
        render={undefined}
      />
    )
  } else {
    if (internal && url && window.location.pathname === props.path) {
      return <Redirect to={url} />
    }
  }

  return !props.authenticated ? (
    <Route {...props} component={props.component} render={undefined} />
  ) : (
    <Redirect to={props.getRedirectPath(queries)} />
  )
}

export default LoginRoute
