const { REACT_APP_SOCIAL_SOCKET_URL } = process.env
interface IUrl {
  [key: string]: string
}
/**
 * List of all the URLs that may be used
 * for communication with the backend
 */
const getUrls = (): IUrl => {
  const url: IUrl = {
    // user
    ACTIVE_USERS_URL: '/active_user',
    USER_LOG_URL: '/active_user/user_log',
    // auth
    SEND_OTP: '/auth/send_otp/',
    SEND_SIGNUP_OTP: '/oh_users/student/signup',
    VERIFY_OTP: '/auth/verify_otp/',
    VERIFY_SIGNUP_OTP: '/oh_users/student/verify',
    REFRESH_TOKEN: '/auth/refresh_token/',
    //schedule
    SCHEDULES_URL: 'oh_schedules/schedule',
    DASHBOARD_SCHEDULES_URL: 'oh_schedules/dashboard',
    DEMOS_URL: '/oh_growth/demo',
    DASHBOARD_DEMOS_URL: '/oh_growth/demo/dashboard',
    PAYMENTS_URL: '/oh_payments/invoice',
    DASHBOARD_PAYMENTS_URL: '/oh_payments/invoice/dashboard',
    PAYOUT_PAYMENTS_URL: '/oh_payments/pay_out',
    DASHBOARD_PAYOUT_PAYMENTS_URL: '/oh_payments/pay_out/dashboard',
    INVOICES_PAYOUT_PAYMENTS_URL: '/oh_payments/pay_out/invoices',
    GENERATE_RAZORPAY_ORDER_ID: '/oh_payments/create_razorpay_order',
    VERIFY_RAZAORPAY_PAYMENT: '/oh_payments/verify_razorpay_order',
    // classes
    CLASSES_URL: '/oh_classes',
    BATCHES_URL: '/oh_classes/batch',
    DASHBOARD_BATCHES_URL: '/oh_classes/batch/dashboard',
    COURSES_URL: '/oh_classes/course',
    CLASS_ATTENDANCE_URL: '/oh_classes/class_wise_attendance',
    STUDENT_ATTENDANCE_URL: '/oh_classes/student_wise_attendance',
    INDIVIDUAL_CLASS_ATTENDANCE_URL: '/oh_classes/attendance',
    //server time
    SERVER_TIME: '/time/now/',
    // events
    EVENTS_URL: '/oh_events/event/user',
    EVENTS_REGISTRATION_URL: '/oh_events/event_registration',
    EVENTS_LIST: '/oh_events/event',
    EVENTS_REGISTERED_FOR_URL: '/oh_events/event/registered/user',
    EVENTS_IN_SCHEDULE_MOBILE: 'oh_events/get_student_registered_events',
    // lobby
    LOBBY_SECTIONS_URL: '/oh_lobby/sections',
    LOBBY_SUMMARY_URL: '/oh_lobby/summary',
    LOBBY_DOOR_DETAILS_URL: '/oh_lobby/details',
    LOBBY_DOOR_ATTENDANCE_URL: 'oh_lobby/attendance',
    LOBBY_DOOR_REGISTRATION_URL: 'oh_lobby/cta',
    LOBBY_MY_JOURNEY_MENTOR_URL: 'oh_lobby/get_journey_mentor/',
    LOBBY_IS_EVENT_ONLY: 'oh_lobby/event/status/',
    // avatar
    USER_AVATAR_URL: '/oh_avatars/user/avatar/',
    AVATAR_COMPONENTS_URL: '/oh_avatars/avatar_component',
    AVATAR_RESET_URL: '/oh_avatars/user/avatar/reset/',
    MODIFY_AVATAR_URL: '/oh_avatars/user/avatar',
    AVATAR_COMPLETE: '/oh_avatars/user/avatar/done',
    // social
    POSTS_INITIAL_URL: '/oh_social_lobby/post_initial',
    POSTS_URL: '/oh_social_lobby/post',
    VIBE_URL: '/oh_social_lobby/vibe',
    THOUGHT_URL: '/oh_social_lobby/thought',
    DROPDOWN_QUESTIONS_URL: '/oh_social_lobby/questions',

    SOCIAL_WS_URL: `${REACT_APP_SOCIAL_SOCKET_URL}/ws/lobby/lobby_room`,
    VIBES_COUNT: '/oh_social_lobby/vibes_count',
    // report cards
    REPORT_CARD_DASHBOARD_URL: '/oh_classes/report_card/dashboard/',
    // 3rd Party APIs
    YOUTUBE_DATA_API: 'https://www.googleapis.com/youtube/v3/search'
  }

  return url
}

export default getUrls
