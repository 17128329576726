import qs, { ParsedUrlQueryInput } from 'querystring'

import DefaultLoader from '@components/common/Loader/Loader'
import LobbyLoader from '@components/pages/LobbyNew/components/LobbyLoader'
import { ACCESS_TOKEN_KEY } from '@constants/authenticationTokenKeys'
import { getCookie } from '@helpers/cookie'
import { isTokenExpired } from '@helpers/getJwtDecodedData'
import { getUserTypeFromToken } from '@helpers/user/auth'
import { checkLogin } from '@store/thunks/userAuthenticationThunk'
import { isEmpty } from 'lodash'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'

import LoginRoute from './LoginRoute'
import PrivateRoute from './PrivateRoute'
import {
  defaultRoute,
  publicRoutes,
  privateRoutes,
  userTypes
} from './RoutesConfig'
import navigationPaths, {
  publicNavigationPaths,
  privateNavigationPaths
} from './RoutesPaths'

const ForbiddenPage = React.lazy(() => import('@components/pages/Forbidden'))

interface SuspenseLoader {
  [key: string]: React.ReactNode
}

const suspenseLoaders: SuspenseLoader = {
  defaultLoader: <DefaultLoader />,
  lobbyLoader: <LobbyLoader />
}

const RouteSwitcher: React.FunctionComponent = () => {
  const user = useSelector((state: any) => state.user)

  const dispatch = useDispatch()

  const [authenticated, userType] = React.useMemo(() => {
    // check with state
    if (user?.authenticated && user?.type) {
      return [user.authenticated, user.type]
    }

    // if not got proper authenticated value in store state , check token in storage once
    if (ACCESS_TOKEN_KEY) {
      const accessToken = getCookie(ACCESS_TOKEN_KEY)
      /**
       * check with isTokenExpired,
       * if token is a valid JWT token , it will return true/false
       * otherwise undefined
       */
      const expired: boolean | undefined = isTokenExpired(accessToken || '')

      if (typeof expired === 'boolean' && !expired) {
        /**
         * now as token is valid JWT token (may be expired)
         * so now get user type from token
         */
        const userType = getUserTypeFromToken(accessToken)
        return [!expired, userType]
      }
    }

    // return authenticated false with userType none
    return [false, 'none']
  }, [user.authenticated, user.type])

  React.useEffect(() => {
    dispatch(checkLogin({}))
  }, [dispatch])

  const getRedirectPath = React.useCallback(
    (routes: any, redirectQueries: any) => {
      let routeExist = false
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].path === window.location.pathname) {
          routeExist = true
        }
      }
      const redirectDefaultRoute = authenticated
        ? navigationPaths.schedule
        : navigationPaths.login

      const queryString = !isEmpty(redirectQueries)
        ? `?${qs.stringify(redirectQueries)}`
        : ''
      return `${
        routeExist ||
        window.location.pathname.startsWith(navigationPaths.logout)
          ? window.location.pathname
          : redirectDefaultRoute
      }${queryString}`
    },
    [authenticated, userType]
  )

  const [publicNavigationPathsArray, privateNavigationPathsArray] =
    React.useMemo(() => {
      return [
        Object.values(publicNavigationPaths),
        Object.values(privateNavigationPaths)
      ]
    }, [])

  const publicRoutesElement = React.useMemo(() => {
    return publicRoutes.map(({ path, component }, index: number) => (
      <LoginRoute
        exact
        authenticated={authenticated}
        path={path}
        component={component}
        key={index}
        getRedirectPath={(redirectQueries: ParsedUrlQueryInput | undefined) =>
          getRedirectPath(privateRoutes, redirectQueries)
        }
      />
    ))
  }, [authenticated, getRedirectPath])

  const privateRoutesElement = React.useMemo(() => {
    const defaultSuspenseLoaderElements = privateRoutes
      .filter((route) => route.suspenseLoaderComponent === undefined)
      .map(({ path, component, existsFor }, index) => {
        const authorized = existsFor.indexOf(userType) > -1

        return (
          <PrivateRoute
            exact
            authenticated={authenticated}
            path={path}
            component={authorized ? component : ForbiddenPage}
            key={index}
            getRedirectPath={(
              redirectQueries: ParsedUrlQueryInput | undefined
            ) => getRedirectPath(publicRoutes, redirectQueries)}
          />
        )
      })

    const customSuspenseLoaderRoutesElement = privateRoutes
      .filter((route: any) => route.suspenseLoaderComponent !== undefined)
      .map(({ path, component, suspenseLoaderComponent, existsFor }, index) => {
        const authorized = existsFor.indexOf(userType) > -1

        if (authenticated) {
          return (
            <Route exact path={path} key={index}>
              <Redirect to={navigationPaths.schedule} />
            </Route>
          )
        }

        return (
          <React.Suspense
            key={index}
            fallback={
              suspenseLoaderComponent
                ? suspenseLoaders[suspenseLoaderComponent] || null
                : null
            }
          >
            <PrivateRoute
              exact
              authenticated={authenticated}
              path={path}
              component={authorized ? component : ForbiddenPage}
              getRedirectPath={(
                redirectQueries: ParsedUrlQueryInput | undefined
              ) => getRedirectPath(publicRoutes, redirectQueries)}
            />
          </React.Suspense>
        )
      })

    return (
      <>
        <React.Suspense fallback={suspenseLoaders.defaultLoader || null}>
          {defaultSuspenseLoaderElements}
        </React.Suspense>
        {customSuspenseLoaderRoutesElement}
      </>
    )
  }, [authenticated, getRedirectPath, userType])

  return (
    <Switch>
      <Route exact path={publicNavigationPathsArray}>
        <React.Suspense fallback={suspenseLoaders.defaultLoader || null}>
          {publicRoutesElement}
        </React.Suspense>
      </Route>

      <Route exact path={privateNavigationPathsArray}>
        <Switch>{privateRoutesElement}</Switch>
      </Route>

      <React.Suspense fallback={suspenseLoaders.defaultLoader || null}>
        <PrivateRoute
          authenticated
          path={defaultRoute.path}
          component={defaultRoute.component}
          getRedirectPath={(redirectQueries: ParsedUrlQueryInput | undefined) =>
            getRedirectPath(privateRoutes, redirectQueries)
          }
        />
      </React.Suspense>
    </Switch>
  )
}

export default RouteSwitcher
