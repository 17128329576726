// import {
//   DO_NOT_ASK_FOR_AVATAR,
//   DO_NOT_ASK_FOR_LOBBY
// } from '@constants/permissionKeys'
// import { getCookie } from '@helpers/cookie'
// import useIsMobileView from '@hooks/displaySize/useIsMobileView'

import Notification from '@components/common/Notification'
import { userTypes } from '@routes/RoutesConfig'
import navigationPaths from '@routes/RoutesPaths'
import RouteSwitcher from '@routes/RouteSwitcher'
import { updateServerTime } from '@store/actions/serverSide'
import { getMyAvatarDetails } from '@store/thunks/myAvatarThunk'
import { getServerTime } from '@store/thunks/serverSide'
import React from 'react'
import ReactNotification from 'react-notifications-component'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import 'react-toastify/dist/ReactToastify.css'
// components, styles and UI elements
import 'react-notifications-component/dist/theme.css'

import Menubar from './components/common/Menubar/Menubar'
import Sidebar from './components/common/Sidebar/Sidebar'
import * as styles from './styles'

const App = () => {
  const isServiceWorkerUpdated = useSelector(
    (state: any) => state.app.serviceWorkerUpdated
  )
  const serviceWorkerRegistration = useSelector(
    (state: any) => state.app.serviceWorkerRegistration
  )

  // const avatar = useSelector((state: any) => state.avatar)
  const location = useLocation()
  const { authenticated, type } = useSelector((state: any) => state.user)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(getServerTime())
  }, [dispatch])

  React.useEffect(() => {
    const handleServerClock = () => {
      dispatch(updateServerTime())
    }

    const timer = window.setInterval(handleServerClock, 1000)

    return () => {
      if (timer) {
        clearInterval(timer)
      }
    }
  }, [dispatch])

  React.useEffect(() => {
    const handleServiceWorkerUpdate = async () => {
      const updateServiceWorker = async () => {
        const registrationWaiting = serviceWorkerRegistration?.waiting

        if (registrationWaiting) {
          await serviceWorkerRegistration.unregister()

          registrationWaiting.postMessage({ type: 'SKIP_WAITING' })

          window.location.reload()

          registrationWaiting.addEventListener('statechange', (e: any) => {
            if (e.target.state === 'activated') {
              console.log('service worker activated')
            }
          })
        }
      }

      if (process.env.NODE_ENV === 'production' && isServiceWorkerUpdated) {
        console.log('updating service worker')
        await updateServiceWorker()
      }
    }

    handleServiceWorkerUpdate()
  }, [isServiceWorkerUpdated, serviceWorkerRegistration])

  React.useEffect(() => {
    if (authenticated && [userTypes.STUDENT, userTypes.ADMIN].includes(type)) {
      getMyAvatarDetails()(dispatch)
    }
  }, [authenticated, type, dispatch])

  const fullScreen = React.useMemo(() => {
    return location.pathname === navigationPaths.home
  }, [location])

  const isHidden = false

  // const isMobile = useIsMobileView()
  // const is_default = avatar?.myAvatar?.is_default || true
  // let isHidden = false
  // if (!isHidden) {
  //   if (
  //     isMobile &&
  //     [true, undefined].includes(is_default) &&
  //     !getCookie(DO_NOT_ASK_FOR_AVATAR)
  //   ) {
  //     isHidden = true
  //   } else {
  //     if (isMobile && !getCookie(DO_NOT_ASK_FOR_LOBBY)) {
  //       isHidden = true
  //     }
  //   }
  // }

  // if (
  //   !isMobile &&
  //   [navigationPaths.home, navigationPaths.avatar].includes(location.pathname)
  // ) {
  //   isHidden = true
  // }

  return (
    <>
      {/* React notifications container is rendered once inside the document 
        and new notifications can be displayed to the user using the "showNotification" function 
        It will be rendered and taken care of automatically. This component doesn't affect the 
        placemenet of any other component*/}
      <ReactNotification />
      <Notification />
      {/* Constant top menubar displayed on all pages */}
      {!isHidden && <Menubar />}
      <styles.AppBody full={fullScreen}>
        {/* Sidebar displayed on all pages except login */}
        <Sidebar />
        {/* this container is the place where the component will keep changing based on the route */}
        <styles.PageContainer full={fullScreen}>
          {/* Route based component switcher */}
          <RouteSwitcher />
        </styles.PageContainer>
      </styles.AppBody>
    </>
  )
}

export default App
