// USERS
export const GET_ACTIVE_USERS = 'GET_ACTIVE_USERS'
export const GET_USERS_LOG = 'GET_USERS_LOG'

// EVENTS
export const GET_EVENTS = 'GET_EVENTS'
export const GET_ALL_EVENTS = 'GET_ALL_EVENTS'
export const GET_REGISTERED_EVENTS = 'GET_REGISTERED_EVENTS'
export const GET_IS_EVENT_ONLY = 'GET_IS_EVENT_ONLY'

// SECTIONS
export const GET_SECTIONS = 'GET_SECTIONS'

// HOME SUMMARY
export const GET_SUMMARY = 'GET_SUMMARY'

// JOURNEY MENTOR
export const GET_JOURNEY_MENTOR = 'GET_JOURNEY_MENTOR'
