import { WA_URL } from '@constants/whatsappURL'

interface NavigationPaths {
  [key: string]: string
}

export const publicNavigationPaths: NavigationPaths = {
  login: '/login',
  signup: '/signup'
}

export const privateNavigationPaths: NavigationPaths = {
  attendance: '/attendance',
  // singleAttendance: '/attendance/:batch_id',
  avatar: '/avatar',
  contactUs: WA_URL,
  demos: '/demos',
  home: '/',
  payments: '/payments',
  reportCards: '/report-cards',
  quiz: '/quiz',
  myDrive: '/my-drive',
  questionBank: '/question-bank',
  schedule: '/schedule',
  logout: '/logout'
}

const navigationPaths: NavigationPaths = {
  ...publicNavigationPaths,
  ...privateNavigationPaths
}

export default navigationPaths
