import { ACCESS_TOKEN_KEY } from '@constants/authenticationTokenKeys'
import { IJwtData } from '@interfaces/services/IJwtData'
import { getCookie } from '@helpers/cookie'

import jwt_decode from 'jwt-decode'
import Dayjs from '@modules/Dayjs'

export function getJwtDecodedData(): IJwtData {
  const token = getCookie(ACCESS_TOKEN_KEY)!
  const data: IJwtData = jwt_decode(token)
  return data
}

export const decodeJWT = (token: string): IJwtData | undefined => {
  try {
    return jwt_decode(token)
  } catch (error) {
    console.log('invalid input :- ', error)
  }
}

export const isTokenExpired = (accessToken: string): boolean | undefined => {
  try {
    const decodedToken: IJwtData | undefined = decodeJWT(accessToken)
    if (decodedToken) {
      return isExpired(decodedToken?.exp)
    }
  } catch (error) {
    console.log('invalid input :- ', error)
  }
}

export const isExpired = (expDate: any) => {
  if (!expDate) return true
  return Date.now() >= expDate * 1000
}

export const shouldRefreshToken = (expDate: any): boolean | undefined => {
  if (!expDate) return true
  const diffInDays = Dayjs(expDate * 1000).diff(Date.now(), 'day')
  return diffInDays < 8
}
