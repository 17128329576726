import { store as notificationStore } from 'react-notifications-component'

export const notify = (
  title: string,
  message: string,
  type: any,
  content?: any,
  duration = 3000
) => {
  try {
    message = message || ' '
    notificationStore.addNotification({
      title: title,
      message: message,
      type: type,
      content,
      insert: 'top',
      container: 'top-right',
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismiss: {
        duration: duration,
        onScreen: content ? false : true
      }
    })
  } catch (e) {}
}
