import styled from 'styled-components'
interface UserTypeProps {
  type: string
}
export const Container = styled.div`
  width: 18rem;
  position: absolute;
  top: 4rem;
  right: 0;
  z-index: 1;
  margin-right: 3.5rem;
  border-radius: 5px;
  background: var(--white);
  box-shadow: var(--oh-default-shadow);

  @media (max-width: 500px) {
    width: 16rem;
    margin-right: 2.8rem;
    z-index: 1;
  }
`

export const PictureContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: rgba(88, 184, 232, 0.1);
`

export const Picture = styled.img`
  width: 5rem;
  height: auto;
  margin: 1rem;
`

export const Info = styled.div`
  width: 100%;
  height: 4rem;
  border-bottom: 1px solid #d9d9d9;
`

export const Name = styled.div`
  font-size: 1rem;
  font-weight: bold;
  color: var(--oh-black);
  margin: 1rem 1.2rem;
`

export const Phone = styled.div`
  font-size: 1rem;
  margin-top: 1rem;
  color: var(--oh-black);
  margin: 1rem 1.2rem;
`

export const LogoutContainer = styled.div`
  width: 100%;
  padding: 1rem 1.2rem;
  display: flex;
`

export const LogoutText = styled.div`
  font-size: 1rem;
  margin-right: 1rem;
`

export const LogoutIcon = styled.img`
  width: 1.5rem;
  height: auto;
  cursor: pointer;
`

export const MyAvatar = styled.div<UserTypeProps>`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: ${(props) =>
    props.type === 'student' ? '#4c82d3' : 'var(--oh-v2-vl-3)'};
  cursor: pointer;
  margin: 1rem;
`
