import RoutesPages from './RoutesPages'
import navigationPaths from './RoutesPaths'

// types
export enum userTypes {
  NONE = 'none',
  STUDENT = 'student',
  TEACHER = 'teacher',
  ADMIN = 'admin',
  SOCIAL_LOBBY_ADMIN = 'social_lobby_admin'
}

interface RouteDefinition {
  path: string
  existsFor: userTypes[]
  component?: React.LazyExoticComponent<React.FunctionComponent>
  sidebarName: string
  sidebarIcon: string
  sidebarVisibilty: userTypes[]
  lobbyVisibilty: userTypes[]
  hidden: boolean
  suspenseLoaderComponent?: string | null
}

export const defaultRoute = {
  component: RoutesPages.NotFoundPage,
  path: '*'
}

export const publicRoutes: RouteDefinition[] = [
  {
    path: navigationPaths.login,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    component: RoutesPages.LoginPage,
    sidebarName: '',
    sidebarIcon: '',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [],
    hidden: false
  },
  {
    path: navigationPaths.signup,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    component: RoutesPages.SignupPage,
    sidebarName: '',
    sidebarIcon: '',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [],
    hidden: false
  }
]

export const privateRoutes: RouteDefinition[] = [
  {
    path: navigationPaths.home,
    existsFor: [userTypes.STUDENT, userTypes.ADMIN],
    component: RoutesPages.LobbyNewPage,
    sidebarName: 'lobby',
    sidebarIcon: 'Home.svg',
    sidebarVisibilty: [],
    lobbyVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    hidden: false,
    suspenseLoaderComponent: 'lobbyLoader'
  },
  {
    path: navigationPaths.schedule,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    component: RoutesPages.DashboardPage,
    sidebarName: 'my schedule',
    sidebarIcon: 'MyScheduleIcon.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    hidden: false
  },
  {
    path: navigationPaths.avatar,
    existsFor: [userTypes.STUDENT, userTypes.ADMIN],
    component: RoutesPages.AvatarPage,
    sidebarName: 'avatar',
    sidebarIcon: 'Home.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    lobbyVisibilty: [],
    hidden: true
  },
  // {
  //   path: navigationPaths.attendance,
  //   existsFor: [userTypes.TEACHER],
  //   component: RoutesPages.Allbatches,
  //   sidebarName: 'my classes',
  //   sidebarIcon: 'ClassesMenu.svg',
  //   sidebarVisibilty: [userTypes.TEACHER],
  //   lobbyVisibilty: [],
  //   hidden: false
  // },
  // {
  //   path: navigationPaths.singleAttendance,
  //   existsFor: [userTypes.TEACHER],
  //   component: RoutesPages.SingleBatch,
  //   sidebarName: 'my classes batchwise',
  //   sidebarIcon: 'ClassesMenu.svg',
  //   sidebarVisibilty: [],
  //   lobbyVisibilty: [],
  //   hidden: true
  // },
  {
    path: navigationPaths.demos,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    component: RoutesPages.DemosPage,
    sidebarName: 'mini courses',
    sidebarIcon: 'TV.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    hidden: false
  },
  {
    path: navigationPaths.quiz,
    existsFor: [userTypes.TEACHER],
    component: RoutesPages.QuizPage,
    sidebarName: 'quiz',
    sidebarIcon: 'question.svg',
    sidebarVisibilty: [userTypes.TEACHER],
    lobbyVisibilty: [],
    hidden: true
  },
  {
    path: navigationPaths.myDrive,
    existsFor: [userTypes.TEACHER],
    component: RoutesPages.MyDrivePage,
    sidebarName: 'my drive',
    sidebarIcon: 'MyDriveIcon.svg',
    sidebarVisibilty: [userTypes.TEACHER],
    lobbyVisibilty: [],
    hidden: false
  },
  {
    path: navigationPaths.questionBank,
    existsFor: [userTypes.ADMIN],
    component: RoutesPages.QuestionBankPage,
    sidebarName: 'question bank',
    sidebarIcon: 'question.svg',
    sidebarVisibilty: [userTypes.ADMIN],
    lobbyVisibilty: [],
    hidden: false
  },
  {
    path: navigationPaths.payments,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    component: RoutesPages.PaymentsPage,
    sidebarName: 'payments',
    sidebarIcon: 'INR.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    hidden: false
  },
  {
    path: navigationPaths.reportCards,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER],
    component: RoutesPages.ReportCardsPage,
    sidebarName: 'reports',
    sidebarIcon: 'report-card.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER],
    lobbyVisibilty: [userTypes.STUDENT],
    hidden: false
  },
  {
    path: navigationPaths.contactUs,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    sidebarName: 'contact us',
    sidebarIcon: 'Whatsapp.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    hidden: false
  },
  {
    path: navigationPaths.logout,
    existsFor: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    component: RoutesPages.LogoutPage,
    sidebarName: 'logout',
    sidebarIcon: 'LogoutIcon.svg',
    sidebarVisibilty: [userTypes.STUDENT, userTypes.TEACHER, userTypes.ADMIN],
    lobbyVisibilty: [userTypes.STUDENT, userTypes.ADMIN],
    hidden: true
  }
]
