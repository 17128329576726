import React from 'react'

// components, styles and UI elements
import * as LoaderStyles from './styles'

// types
export interface LoaderProps {
  small?: boolean
}

/**
 * Used as a placeholder while some other component is
 * fetching data async
 *
 * @param small - displays a compact version of the loader
 */
const Loader: React.FunctionComponent<LoaderProps> = ({ small }) => {
  return (
    <LoaderStyles.Wrapper isSmall={small}>
      <LoaderStyles.BouncingLogo />
    </LoaderStyles.Wrapper>
  )
}

export default Loader
