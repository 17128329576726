import { Action } from 'redux'

import {
  INITIALIZE_SERVICE_WORKER,
  UPDATE_SERVICE_WORKER
} from '../actionTypes/app'

export interface UploadServiceWorkerPayload {
  waiting: {
    postMessage: Function
    addEventListener: Function
  }
}

interface InitializeServiceWorker extends Action {
  payload?: any // FIXME: not needed , putting this temporarily bcz of typing issue
}

interface UpdateServiceWorker extends Action {
  payload: UploadServiceWorkerPayload
}

export const initializeServiceWorker = (): InitializeServiceWorker => ({
  type: INITIALIZE_SERVICE_WORKER
})

export const updateServiceWorker = (
  payload: UploadServiceWorkerPayload
): UpdateServiceWorker => ({
  type: UPDATE_SERVICE_WORKER,
  payload
})

export type AppActionTypes = UpdateServiceWorker | InitializeServiceWorker
