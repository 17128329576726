import * as actionTypes from '../actionTypes/myDemos'

const initialState = {
  demos: {
    isLoaded: false,
    isError: false,
    errorMessage: '',
    data: []
  }
}

const setDemos = (state: any, payload: any) => {
  return {
    ...state,
    demos: {
      ...state.demos,
      isLoaded: true,
      isError: false,
      data: payload
    }
  }
}
const setDemosFailure = (state: any, payload: any) => {
  return {
    ...state,
    demos: {
      ...state.demos,
      isLoaded: false,
      isError: true,
      errorMessage: payload
    }
  }
}

const resetState = () => initialState

const demosReducers = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_MY_DEMOS_SUCCESS:
      return setDemos(state, action.payload)
    case actionTypes.GET_MY_DEMOS_FAILURE:
      return setDemosFailure(state, action.payload)
    case actionTypes.RESET_DEMOS:
      return resetState()
    default:
      return state
  }
}
export default demosReducers
