import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  USER_TYPE_KEY
} from '@constants/authenticationTokenKeys'
import {
  getCookie,
  setCookie,
  removeCookie,
  removeAllCookies
} from '@helpers/cookie'
import { isExpired } from '@helpers/getJwtDecodedData'
import { notify } from '@helpers/notification'
import { getUserTypeFromToken } from '@helpers/user/auth'
import { ohrAxios } from '@services/httpInstance/axios'
import getUrls from '@utils/getUrls'
import jwtDecode from 'jwt-decode'

import * as userActions from '../actions/userAuthentication'

const setUserState = (
  preferred_username: string,
  name: string,
  access_token: string,
  refresh_token: string,
  type: string
) => (dispatch: any) => {
  dispatch(userActions.setTokens({ access_token, refresh_token }))
  dispatch(userActions.setUserType(type))
  dispatch(
    userActions.setUserInfo({
      user_name: preferred_username,
      full_name: name
    })
  )
  dispatch(userActions.setAuth(true))
}

export const removeUserCookies = () => {
  removeCookie(ACCESS_TOKEN_KEY)
  removeCookie(REFRESH_TOKEN_KEY)
  removeCookie(USER_TYPE_KEY)
}

export const handleErrorInAuth = (e?: Error) => (dispatch: any) => {
  notify('oh! you have been logged out', 'please login again', 'warning')
  dispatch(performLogout({}))
  e && console.log(e)
  window.open('/login', '_self')
}

export const performLogin = (data: any) => (dispatch: any) => {
  const { preferred_username, name, exp } = jwtDecode(data.access_token)

  const type = getUserTypeFromToken(data.access_token)

  // first removing all cookies
  removeAllCookies()

  const { access_token, refresh_token } = data

  /**
   * setting cookies to use by other products, oh meets..
   */
  const cookieMaxAge = {
    maxAge: exp - Math.floor(Date.now() / 1000)
  }

  setCookie(ACCESS_TOKEN_KEY, access_token, cookieMaxAge)
  setCookie(REFRESH_TOKEN_KEY, refresh_token, cookieMaxAge)
  setCookie(USER_TYPE_KEY, type, cookieMaxAge)

  dispatch(
    setUserState(preferred_username, name, access_token, refresh_token, type)
  )
}

export const performLogout = (data: any) => (dispatch: any) => {
  dispatch(userActions.logout())
  removeUserCookies()
}

export const checkLogin = (data: any) => (dispatch: any) => {
  const access_token = getCookie(ACCESS_TOKEN_KEY)
  const refresh_token = getCookie(REFRESH_TOKEN_KEY)

  if (access_token && refresh_token) {
    try {
      const { preferred_username, name, exp } = jwtDecode(access_token || '')
      const type = getUserTypeFromToken(access_token)

      const expired = isExpired(exp)
      if (expired) {
        const { exp: refresh_token_exp } = jwtDecode(refresh_token || '')
        const refreshTokenExpired = isExpired(refresh_token_exp)
        if (!refreshTokenExpired) {
          dispatch(refreshToken())
          return
        }
        dispatch(handleErrorInAuth())
        return
      }

      dispatch(
        setUserState(
          preferred_username,
          name,
          access_token,
          refresh_token,
          type
        )
      )
    } catch (e) {
      dispatch(handleErrorInAuth(e))
    }
  } else {
    dispatch(performLogout({}))
  }
}

export const refreshToken = () => async (dispatch: any) => {
  const { REFRESH_TOKEN } = getUrls()

  try {
    const refresh_token = getCookie(REFRESH_TOKEN_KEY)
    if (refresh_token) {
      removeUserCookies()
      const data = await ohrAxios.post(REFRESH_TOKEN, { refresh_token })
      if (data && data.data && data.data.success) {
        dispatch(performLogin(data.data))
        return
      }
    }
    dispatch(handleErrorInAuth())
  } catch (e) {
    dispatch(handleErrorInAuth(e))
  }
}
