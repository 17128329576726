import { useState, useEffect } from 'react'
import { isMobileOnly as _isMobile } from 'react-device-detect'

/**
 * This hook returns the screen/client device type
 * desktop or phone and can change the rendering of
 * componenets and styles based on the device type
 */
const useIsMobileView = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(false)

  useEffect(() => {
    if (_isMobile) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [])

  return isMobile
}

export default useIsMobileView
