import { ohrAxios } from '@services/httpInstance/axios'

import { urlencode } from '../../../helpers/urlHelpers'

export const avatarAPI = (url: string, filters?: any) => {
  return ohrAxios
    .get(urlencode(url, filters))
    .then((data: any) => data.data)
    .catch((err: any) => {
      throw err
    })
}
