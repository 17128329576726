import styled, { keyframes } from 'styled-components'
const ohLogo = require('@assets/Illustrations/SVGs/oh-logo.svg')

export const BouncingLogo = styled.div`
  height: 80px;
  width: 80px;
  background: url(${ohLogo}) center no-repeat;
  background-size: contain;
  background-color: transparent;

  animation: bounce 0.3 infinite alternate;
  -webkit-animation: bounce 0.3s infinite alternate;

  @keyframes bounce {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1.2);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: scale(0.9);
    }
    to {
      transform: scale(1.2);
    }
  }
`

interface LoaderProps {
  isSmall?: boolean
}

export const Wrapper = styled.div<LoaderProps>`
  position: ${(p) => (p.isSmall ? 'block' : 'fixed')};
  width: ${(p) => (p.isSmall ? '100%' : '100vw')};
  height: ${(p) => (p.isSmall ? '15rem' : '100vh')};

  left: ${(p) => (p.isSmall ? 'unset' : '0')};
  top: ${(p) => (p.isSmall ? 'unset' : '0')};

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;

  @media (max-width: 500px) {
    width: 100%;
    height: calc(100vh - 8rem);
  }
`
