import {
  IPost,
  Question,
  Vibe,
  Thought,
  PostInteractionDetails
} from '@interfaces/social'
import { InitialState } from '@interfaces/stores/mySocial'

import * as actionTypes from '../actionTypes/mySocial'

const initialState: InitialState = {
  isLoading: false,
  isError: false,
  errorStatus: '',
  modalPostUid: '',
  modalPostDetails: null,
  postsInitial: [],
  posts: [],
  dropdownQuestions: []
}

const setError = (state: InitialState, payload: any) => {
  return {
    ...state,
    isError: payload.isError,
    errorStatus: payload.errorStatus
  }
}

const setLoading = (state: InitialState, payload: any) => {
  return {
    ...state,
    isLoading: payload.isLoading
  }
}

const setInitialPosts = (state: InitialState, payload: any) => {
  const postsInitial: IPost[] = payload.postsInitial
  return {
    ...state,
    postsInitial
  }
}

const setPosts = (state: InitialState, payload: any) => {
  const posts: IPost[] = payload.posts
  return {
    ...state,
    posts
  }
}

const setDropdownQuestions = (state: InitialState, payload: any) => {
  const dropdownQuestions: Question[] = payload.questions
  return {
    ...state,
    dropdownQuestions
  }
}

const createSinglePost = (state: InitialState, payload: any) => {
  const newPost: IPost = payload.post
  return {
    ...state,
    posts: [newPost, ...state.posts]
  }
}

const updateVibe = (state: InitialState, payload: any) => {
  const newVibe: Vibe = payload.vibe
  const newPosts = state.posts.map((post) => {
    if (post.uuid === newVibe.uuid) {
      post.vibes_count++
    }
    return post
  })
  const newPostInitial = state.postsInitial.map((post) => {
    if (post.uuid === newVibe.uuid) {
      post.vibes_count++
    }
    return post
  })
  const newModalPostDetails = Object.create(state.modalPostDetails)

  if (state.modalPostUid && state.modalPostDetails) {
    newModalPostDetails.vibes = newModalPostDetails.vibes.slice()
    newModalPostDetails.vibes.push(newVibe)
  }

  return {
    ...state,
    modalPostDetails: newModalPostDetails,
    posts: newPosts,
    postsInitial: newPostInitial
  }
}

const updateThought = (state: InitialState, payload: any) => {
  const newThought: Thought = payload.thought
  const newPosts = state.posts.map((post) => {
    if (post.uuid === newThought.post.uuid) {
      post.thoughts_count++
      post.latest_thought = newThought.post.latest_thought
    }
    return post
  })

  const newPostInitial = state.postsInitial.map((post) => {
    if (post.uuid === newThought.post.uuid) {
      post.thoughts_count++
      post.latest_thought = newThought.post.latest_thought
    }
    return post
  })
  const newModalPostDetails = Object.create(state.modalPostDetails)

  if (state.modalPostUid && state.modalPostDetails) {
    newModalPostDetails.thoughts = newModalPostDetails.thoughts.slice()
    newModalPostDetails.thoughts.push(newThought)
  }

  return {
    ...state,
    modalPostDetails: newModalPostDetails,
    posts: newPosts,
    postsInitial: newPostInitial
  }
}

const deleteThought = (state: InitialState, payload: any) => {
  const deletedThought: Thought = payload.thought

  const newPosts = state.posts.map((post) => {
    if (post.uuid === deletedThought.post.uuid) {
      post.thoughts_count--
    }
    return post
  })

  const newPostInitial = state.postsInitial.map((post) => {
    if (post.uuid === deletedThought.post.uuid) {
      post.thoughts_count--
    }
    return post
  })
  const newModalPostDetails = Object.create(state.modalPostDetails)

  if (state.modalPostUid && state.modalPostDetails) {
    newModalPostDetails.thoughts = newModalPostDetails.thoughts.filter(
      (thought: Thought) => thought.uuid !== deletedThought.uuid
    )
  }

  return {
    ...state,
    modalPostDetails: newModalPostDetails,
    posts: newPosts,
    postsInitial: newPostInitial
  }
}

const deleteVibe = (state: InitialState, payload: any) => {
  const deletedVibe: Vibe = payload.vibe
  const newPosts = state.posts.map((post) => {
    if (post.uuid === deletedVibe.uuid) {
      post.vibes_count--
    }
    return post
  })

  const newPostInitial = state.postsInitial.map((post) => {
    if (post.uuid === deletedVibe.uuid) {
      post.vibes_count--
    }
    return post
  })
  const newModalPostDetails = Object.create(state.modalPostDetails)
  if (state.modalPostUid && state.modalPostDetails) {
    newModalPostDetails.vibes = newModalPostDetails.vibes.filter(
      (vibe: Vibe) => vibe.uuid !== deletedVibe.uuid
    )
  }

  return {
    ...state,
    modalPostDetails: newModalPostDetails,
    posts: newPosts,
    postsInitial: newPostInitial
  }
}

const setModalPost = (state: InitialState, payload: any) => {
  const postUid = payload.postUid
  return {
    ...state,
    modalPostUid: postUid,
    modalPostDetails: null
  }
}

const setModalPostDetails = (state: InitialState, payload: any) => {
  const postInteractionDetails: PostInteractionDetails = payload.postDetails
  const foundPost = state.posts.find((post) => post.uuid === state.modalPostUid)
  if (foundPost) {
    postInteractionDetails.thoughts.unshift({
      uuid: foundPost.uuid,
      content: foundPost.description,
      post: foundPost,
      author: foundPost.author,
      vibes_count: foundPost.vibes_count
    })
  }

  return {
    ...state,
    modalPostDetails: postInteractionDetails
  }
}

const resetPostDetails = (state: InitialState, payload: any) => {
  return {
    ...state,
    modalPostDetails: null
  }
}

const deletePost = (state: InitialState, payload: any) => {
  const deletedPost: IPost = payload.post
  return {
    ...state,
    posts: state.posts.filter((post: IPost) => post.uuid !== deletedPost.uuid)
  }
}

const socialReducers = (state: InitialState = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return setError(state, action.payload)

    case actionTypes.SET_LOADING:
      return setLoading(state, action.payload)

    case actionTypes.SET_POSTS_INITIAL:
      return setInitialPosts(state, action.payload)

    case actionTypes.SET_POSTS:
      return setPosts(state, action.payload)

    case actionTypes.SET_DROPDOWN_QUESTIONS:
      return setDropdownQuestions(state, action.payload)

    case actionTypes.CREATE_SINGLE_POST:
      return createSinglePost(state, action.payload)

    case actionTypes.UPDATE_VIBE:
      return updateVibe(state, action.payload)

    case actionTypes.UPDATE_THOUGHT:
      return updateThought(state, action.payload)

    case actionTypes.SET_MODAL_POST:
      return setModalPost(state, action.payload)

    case actionTypes.SET_MODAL_POST_DETAILS:
      return setModalPostDetails(state, action.payload)

    case actionTypes.RESET_POST_DETAILS:
      return resetPostDetails(state, action)

    case actionTypes.DELETE_POST:
      return deletePost(state, action.payload)

    case actionTypes.DELETE_THOUGHT:
      return deleteThought(state, action.payload)

    case actionTypes.DELETE_VIBE:
      return deleteVibe(state, action.payload)

    default:
      return state
  }
}
export default socialReducers
