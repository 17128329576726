import * as actionTypes from '../actionTypes/myPayments'

const initialState = {
  teacherDuePayment: {
    isLoaded: false,
    isError: false,
    errorMessage: '',
    data: []
  },
  teacherPaidPayment: {
    isLoaded: false,
    isError: false,
    errorMessage: '',
    data: []
  },
  teacherDispatchedPayment: {
    isLoaded: false,
    isError: false,
    errorMessage: '',
    dispatchedDetails: [],
    unDispatchedDetails: {
      count: 0,
      partner_share: 0
    }
  },
  studentDuePayment: {
    isLoaded: false,
    isError: false,
    errorMessage: '',
    data: []
  },
  studentPaidPayment: {
    isLoaded: false,
    isError: false,
    errorMessage: '',
    data: []
  }
}

const setTeachersDuePaymentDetailsSuccess = (state: any, payload: any) => {
  return {
    ...state,
    teacherDuePayment: {
      ...state.teacherDuePayment,
      isLoaded: true,
      isError: false,
      data: payload
    }
  }
}
const setTeachersDuePaymentDetailsFailed = (state: any, payload: any) => {
  return {
    ...state,
    teacherDuePayment: {
      ...state.teacherDuePayment,
      isLoaded: false,
      isError: true,
      errorMessage: payload
    }
  }
}

const setTeachersDispatchedPaymentDetailsSuccess = (
  state: any,
  payload: any
) => {
  return {
    ...state,
    teacherDispatchedPayment: {
      ...state.teacherDispatchedPayment,
      isLoaded: true,
      isError: false,
      dispatchedDetails: payload.dispatchedDetails,
      unDispatchedDetails: payload.unDispatchedDetails
    }
  }
}
const setTeachersDispatchedPaymentDetailsFailed = (
  state: any,
  payload: any
) => {
  return {
    ...state,
    teacherDispatchedPayment: {
      ...state.teacherDispatchedPayment,
      isLoaded: false,
      isError: true,
      errorMessage: payload
    }
  }
}

const setTeachersPaidPaymentDetailsSuccess = (state: any, payload: any) => {
  return {
    ...state,
    teacherPaidPayment: {
      ...state.teacherPaidPayment,
      isLoaded: true,
      isError: false,
      data: payload
    }
  }
}
const setTeachersPaidPaymentDetailsFailed = (state: any, payload: any) => {
  return {
    ...state,
    teacherPaidPayment: {
      ...state.teacherPaidPayment,
      isLoaded: false,
      isError: true,
      errorMessage: payload
    }
  }
}

const setStudentDuePaymentDetailsSuccess = (state: any, payload: any) => {
  return {
    ...state,
    studentDuePayment: {
      ...state.studentDuePayment,
      isLoaded: true,
      isError: false,
      data: payload
    }
  }
}
const setStudentDuePaymentDetailsFailed = (state: any, payload: any) => {
  return {
    ...state,
    studentDuePayment: {
      ...state.studentDuePayment,
      isLoaded: false,
      isError: true,
      errorMessage: payload
    }
  }
}

const setStudentPastPaymentDetailsSuccess = (state: any, payload: any) => {
  return {
    ...state,
    studentPaidPayment: {
      ...state.studentPaidPayment,
      isLoaded: true,
      isError: false,
      data: payload
    }
  }
}
const setStudentPastPaymentDetailsFailed = (state: any, payload: any) => {
  return {
    ...state,
    studentPaidPayment: {
      ...state.studentPaidPayment,
      isLoaded: false,
      isError: true,
      errorMessage: payload
    }
  }
}

const resetState = () => initialState

const paymentsReducers = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_TEACHER_DUE_PAYMENTS_SUCCESS:
      return setTeachersDuePaymentDetailsSuccess(state, action.payload)
    case actionTypes.GET_TEACHER_DUE_PAYMENTS_FAILED:
      return setTeachersDuePaymentDetailsFailed(state, action.payload)

    case actionTypes.GET_TEACHER_PAID_PAYMENTS_SUCCESS:
      return setTeachersPaidPaymentDetailsSuccess(state, action.payload)
    case actionTypes.GET_TEACHER_PAID_PAYMENTS_FAILED:
      return setTeachersPaidPaymentDetailsFailed(state, action.payload)

    case actionTypes.GET_TEACHER_DISPATCHED_PAYMENTS_SUCCESS:
      return setTeachersDispatchedPaymentDetailsSuccess(state, action.payload)
    case actionTypes.GET_TEACHER_DISPATCHED_PAYMENTS_FAILED:
      return setTeachersDispatchedPaymentDetailsFailed(state, action.payload)

    case actionTypes.GET_STUDENT_DUE_PAYMENTS_SUCCESS:
      return setStudentDuePaymentDetailsSuccess(state, action.payload)
    case actionTypes.GET_STUDENT_DUE_PAYMENTS_FAILED:
      return setStudentDuePaymentDetailsFailed(state, action.payload)

    case actionTypes.GET_STUDENT_PAST_PAYMENTS_SUCCESS:
      return setStudentPastPaymentDetailsSuccess(state, action.payload)
    case actionTypes.GET_STUDENT_PAST_PAYMENTS_FAILED:
      return setStudentPastPaymentDetailsFailed(state, action.payload)
    case actionTypes.RESET_PAYMENTS:
      return resetState()
    default:
      return state
  }
}
export default paymentsReducers
