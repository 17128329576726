import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const NotificationComponent = styled(ToastContainer)`
  .Toastify__toast-container {
    width: auto;
  }
  .Toastify__toast {
    box-shadow: 0px 0px 0px rgb(0 0 0 / 0%);
  }

  .Toastify__toast--success,
  .Toastify__toast--warning,
  .Toastify__toast--info,
  .Toastify__toast--error {
    background: transparent;
  }
`
