import { combineReducers } from 'redux'

import appReducer from './appReducer'
import avatarReducer from './myAvatarReducers'
import demosReducers from './myDemosReducers'
import lobbyReducer from './myLobbyReducers'
import paymentsReducers from './myPaymentsReducer'
import reportCardsReducers from './myReportCardsReducer'
import socialReducer from './mySocialReducers'
import scheduleReducer from './myscheduleReducers'
import QuizReducer from './Quiz'
import serverSideReducer from './serverSide'
import userAuthenticationReducer from './userAuthenticationReducer'

const rootReducer = combineReducers({
  app: appReducer,
  schedule: scheduleReducer,
  user: userAuthenticationReducer,
  demos: demosReducers,
  payments: paymentsReducers,
  reportCards: reportCardsReducers,
  quiz: QuizReducer,
  lobby: lobbyReducer,
  serverSide: serverSideReducer,
  avatar: avatarReducer,
  social: socialReducer
})

export default rootReducer
