import React from 'react'
import Lottie from 'react-lottie'

import { generateDefaultOptions, styles } from './config'

const animationData = require('@assets/lottiefiles/oh-lobby-loader.json')

const defaultOptions = generateDefaultOptions(animationData)

const OhLobbyLoader = () => {
  return (
    <div style={styles}>
      <Lottie
        isClickToPauseDisabled
        options={defaultOptions}
        height={300}
        width={300}
        speed={1}
      />
    </div>
  )
}

export default OhLobbyLoader
