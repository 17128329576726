export const SET_ERROR = 'SET_ERROR'
export const SET_LOADING = 'SET_LOADING'
export const SET_POSTS_INITIAL = 'SET_POSTS_INITIAL'
export const SET_POSTS = 'SET_POSTS'
export const SET_DROPDOWN_QUESTIONS = 'SET_DROPDOWN_QUESTIONS'
export const CREATE_SINGLE_POST = 'CREATE_SINGLE_POST'
export const UPDATE_VIBE = 'UPDATE_VIBE'
export const UPDATE_THOUGHT = 'UPDATE_THOUGHT'
export const SET_MODAL_POST = 'SET_MODAL_POST'
export const SET_MODAL_POST_DETAILS = 'SET_MODAL_POST_DETAILS'
export const RESET_POST_DETAILS = 'RESET_POST_DETAILS'
export const DELETE_POST = 'DELETE_POST'
export const DELETE_THOUGHT = 'DELETE_THOUGHT'
export const DELETE_VIBE = 'DELETE_VIBE'
