import { IUser } from '@interfaces/remotes/ohUsers/IUser'
import { userTypes } from '@routes/RoutesConfig'
import jwtDecode from 'jwt-decode'

const getUserRoles = (accessToken: string | null) => {
  if (accessToken) {
    const { realm_access } = jwtDecode(accessToken)

    const { roles } = realm_access
    return roles
  }
}

export const getUserTypeFromToken = (accessToken: string | null) => {
  if (accessToken) {
    const roles = getUserRoles(accessToken)

    if (roles.includes('admin')) {
      return userTypes.ADMIN
    } else if (roles.includes('teacher')) {
      return userTypes.TEACHER
    } else if (roles.includes('student')) {
      return userTypes.STUDENT
    } else if (roles.includes('social_lobby_admin')) {
      return userTypes.SOCIAL_LOBBY_ADMIN
    }
  }

  return userTypes.NONE
}

export const isSocialLobbyAdmin = (accessToken: string | null) => {
  if (accessToken) {
    const roles = getUserRoles(accessToken)
    return roles.includes('social_lobby_admin')
  }
}

const FULL_NAME_REGEX = /^[a-zA-Z]{1,40}(?: +[a-zA-Z]{1,40})+$/

export const validateFullName = (name: IUser) => {
  const { first_name, last_name } = name
  return FULL_NAME_REGEX.test(`${first_name} ${last_name}`)
}
