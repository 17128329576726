import * as actionTypes from '@store/actionTypes/myReportCards'

const initialState = {
  isLoaded: false,
  isError: false,
  errorMessage: '',
  data: [],
  count: 0
}

const setReportCards = (state: any, payload: any) => {
  return {
    ...state,
    isLoaded: true,
    isError: false,
    data: payload.data,
    count: payload.count
  }
}
const setReportCardsFailure = (state: any, payload: any) => {
  return {
    ...state,
    isLoaded: false,
    isError: true,
    errorMessage: payload
  }
}

const reportCardsReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_REPORT_CARDS_START:
      return initialState
    case actionTypes.GET_REPORT_CARDS_SUCCESS:
      return setReportCards(state, action.payload)
    case actionTypes.GET_REPORT_CARDS_FAILED:
      return setReportCardsFailure(state, action.payload)
    default:
      return state
  }
}
export default reportCardsReducer
