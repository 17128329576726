import { store, ReactNotificationOptions } from 'react-notifications-component'

export interface IShowNotification {
  title: string
  message: string
  type: ReactNotificationOptions['type']
}

/**
 * Renders a notification component on the app body
 * can be called from anywhere inside the app body
 *
 * @param title
 * @param message
 * @param type
 */
// eslint-disable-next-line
export default function sendNotification<IShowNotification>(
  title: string,
  message: string,
  type: ReactNotificationOptions['type']
): void {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}
