import qs from 'querystring'

import { isEmpty } from 'lodash'

export function urlencode(baseUrl: string, params: object) {
  const keys = Object.keys(params)
  const values = Object.values(params)
  const getParams = []
  for (let i = 0; i < keys.length; i++) {
    getParams.push(keys[i] + '=' + values[i])
  }
  const finalGetParams = getParams.join('&')
  return encodeURI(baseUrl + '?' + finalGetParams)
}

export function getRedirectUrl(authenticated: boolean) {
  const queries = extractURLQueryParams()
  const { next, ...rest } = queries
  if (authenticated && !isEmpty(next)) {
    if ((next as string).startsWith('http')) {
      return { internal: false, url: next as string }
    }
    return {
      internal: true,
      url: `${next}?${qs.stringify(rest)}`
    }
  }
  return { internal: null }
}

export function extractURLQueryParams() {
  return qs.parse(window.location.search.slice(1))
}
