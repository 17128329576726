import * as actionTypes from '../actionTypes/serverSide'

export const getServerTimeStart = () => {
  return {
    type: actionTypes.GET_SERVER_TIME_START
  }
}
export const getServerTimeSuccess = (payload: any) => {
  return {
    type: actionTypes.GET_SERVER_TIME_SUCCESS,
    payload
  }
}
export const getServerTimeFailed = (error: any) => {
  return {
    type: actionTypes.GET_SERVER_TIME_FAILED,
    payload: error
  }
}

export const updateServerTime = () => {
  return {
    type: actionTypes.UPDATE_SERVER_TIME
  }
}
