import { ACCESS_TOKEN_KEY } from '@constants/authenticationTokenKeys'
import { getCookie } from '@helpers/cookie'
import navigationPaths from '@routes/RoutesPaths'
import axios, { AxiosError } from 'axios'

export const getAxios = (baseURL?: string) => {
  const instance: any = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(
    (response: any) => {
      const token = getCookie(ACCESS_TOKEN_KEY)
      // Check if User is authenticated or not
      if (token) {
        response.headers = {
          Authorization: `Bearer ${token}`
        }
      }
      return response
    },
    (error: any) => {
      return error
    }
  )

  instance.interceptors.response.use(null, (error: AxiosError) => {
    if (error.response) {
      // some server side error
      console.error('error in server response :- ', error.toJSON())

      const errorStatusCode = error.response.status

      if (errorStatusCode === 500) {
        console.error('Something were wrong, we are fixing it !')
      } else if (errorStatusCode >= 400 && errorStatusCode < 500) {
        if (errorStatusCode === 401) {
          // unauthorized token , so logout
          window.location.href = new URL(
            navigationPaths.logout,
            window.location.origin
          ).href
        } else {
          let msg = error.response.data.error || error.response.data.errors

          if (Array.isArray(msg)) {
            msg = msg.join(', ')
          }

          // got something unexpected from server even if response status is proper
          return Promise.reject(new Error(msg || 'Something Went Wrong !'))
        }
      }
    } else if (error.request) {
      // request initiated but no response from server, most probably bcz of network error
      console.error('no response received :- ', error)
      return Promise.reject(new Error('Network Error'))
    } else {
      console.error(
        'Error occured because of something unexpected :- ',
        error.message
      )
    }

    return Promise.reject(error)
  })

  return instance
}

export const ohrAxios = getAxios(process.env.REACT_APP_OHR_API_URL)
export const ohrLegacyAxios = getAxios(process.env.REACT_APP_WEBSITE_URL)
export const ohmAxios = getAxios(process.env.REACT_APP_MEETS_BASE_URL)
