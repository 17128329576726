import * as actionTypes from '../actionTypes/myAvatar'

const initialState = {
  myAvatar: {},
  allComponents: []
}

const setMyAvatar = (state: any, payload: any) => {
  return {
    ...state,
    myAvatar: payload.length > 0 ? payload[0] : {}
  }
}

const modifyMyAvatar = (state: any, payload: any) => {
  const { type } = payload
  return {
    ...state,
    myAvatar: {
      ...state.myAvatar,
      is_default: false,
      [type + '_path']: payload
    }
  }
}

const setAllAvatarComponents = (state: any, payload: any) => {
  return {
    ...state,
    allComponents: payload
  }
}

const avatarReducers = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.GET_MY_AVATAR_DETAILS:
      return setMyAvatar(state, action.payload)
    case actionTypes.MODIFY_MY_AVATAR_DETAILS:
      return modifyMyAvatar(state, action.payload)
    case actionTypes.GET_ALL_AVATAR_COMPONENT_DETAILS:
      return setAllAvatarComponents(state, action.payload)
    default:
      return state
  }
}

export default avatarReducers
