import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface SidebarStyleProps {
  hidden?: boolean
  active?: boolean
}

export const Wrapper = styled.div<SidebarStyleProps>`
  display: ${(p) => (p.hidden ? 'none' : 'intial')};
  z-index: 1;
  width: 13rem;
  background-color: var(--white);
  position: fixed;
  height: 100vh;
  overflow: auto;
  padding-top: 1.5rem;

  @media (max-width: 500px) {
    height: 4rem;
    display: ${(p) => (p.hidden ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    bottom: 0;
    width: 100vw;
    padding-top: 0;

    top: unset;
    box-shadow: var(--oh-default-shadow);
  }
  @media (min-width: 481px) and (max-width: 920px) and (orientation: landscape) {
    width: 100%;
    height: 4rem;
    padding-top: 0;
    display: block;
    bottom: 0rem;
    display: ${(p) => (p.hidden ? 'none' : 'flex')};
    justify-content: space-around;
  }
`

export const Logo = styled.div`
  color: var(--oh-orange);
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  padding: 0 2.5rem;

  @media (max-width: 500px) {
    display: none;
  }
`

export const MenuItem = styled(Link)`
  max-width: 13rem;
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  padding: 1rem 2.5rem;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
  }
  @media (min-width: 481px) and (max-width: 920px) and (orientation: landscape) {
    padding: 1rem 1.5rem;
  }
`

export const MenuItemAnchor = styled.a`
  max-width: 13rem;
  display: ${(p) => (p.hidden ? 'none' : 'flex')};
  padding: 1rem 2.5rem;
  align-items: center;
  cursor: pointer;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    padding: 0 0;
  }
`

export const MenuIcon = styled.img<SidebarStyleProps>`
  height: 1.1rem;
  width: 1.1rem;
  filter: ${(p) =>
    p.active
      ? 'invert(44%) sepia(93%) saturate(1383%) hue-rotate(337deg) brightness(95%) contrast(100%)'
      : 'invert(31%) sepia(13%) saturate(17%) hue-rotate(314deg) brightness(99%) contrast(93%)'};

  @media (max-width: 500px) {
    margin-right: 0;
    margin-bottom: 5px;
  }
`

export const MenuName = styled.header<SidebarStyleProps>`
  margin-left: 1rem;
  font-size: 0.9rem;
  color: ${(p) => (p.active ? 'var(--oh-orange)' : 'var(--oh-black)')};
  font-weight: ${(p) => (p.active ? 'bold' : 'normal')};

  @media (max-width: 500px) {
    font-size: 0.8rem;
    margin-left: 0;
  }
`

export const TermsAndPrivacyPolicy = styled.p`
  position: absolute;
  bottom: 3vh;
  font-size: 12px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--oh-black);

  @media (max-width: 900px) {
    display: none;
  }
`

export const PolicyLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  margin: 0 4px;
`
