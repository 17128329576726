import {
  initializeServiceWorker,
  updateServiceWorker,
  UploadServiceWorkerPayload
} from '@store/actions/app'
import store from '@store/store'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'

// components, styles and UI
import './fonts.css'
import './index.css'
import App from './app/App'
import * as serviceWorker from './serviceWorker'

smoothscroll.polyfill()

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

// React Hot Loading
render()

if (module.hot) {
  module.hot.accept('./app/App', () => {
    render()
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// registering service worker for faster load (https://github.com/facebook/create-react-app/issues/5359)
const serviceWorkerConfig = {
  onSuccess: () => store.dispatch(initializeServiceWorker()),
  onUpdate: (registration: UploadServiceWorkerPayload) =>
    store.dispatch(updateServiceWorker(registration))
}

serviceWorker.register(serviceWorkerConfig)
