export const GET_SCHEDULE_FOR_TODAY_START = 'GET_SCHEDULE_FOR_TODAY_START'
export const GET_SCHEDULE_FOR_TODAY_SUCCESS = 'GET_SCHEDULE_FOR_TODAY_SUCCESS'
export const GET_SCHEDULE_FOR_TODAY_FAILED = 'GET_SCHEDULE_FOR_TODAY_FAILED'

export const GET_SCHEDULE_FOR_WEEK_START = 'GET_SCHEDULE_FOR_WEEK_START'
export const GET_SCHEDULE_FOR_WEEK_SUCCESS = 'GET_SCHEDULE_FOR_WEEK_SUCCESS'
export const GET_SCHEDULE_FOR_WEEK_FAILED = 'GET_SCHEDULE_FOR_WEEK_FAILED'

export const RESET_SCHEDULE = 'RESET_SCHEDULE'
export const SET_COLOR_MAPPING = 'SET_COLOR_MAPPING'
