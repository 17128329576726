import { userTypes } from '@routes/RoutesConfig'

import * as actionTypes from '../actionTypes/userAuthentication'

const initialState = {
  authenticated: false,
  type: userTypes.NONE,
  userInfo: { user_name: null, full_name: null, avatar: '' },
  tokens: {
    access_token: '',
    refresh_token: ''
  }
}

export const setUserAuthenticated = (state: any, payload: any) => {
  return {
    ...state,
    authenticated: payload
  }
}

export const setUserType = (state: any, payload: any) => {
  return {
    ...state,
    type: payload
  }
}

export const setUerInfo = (state: any, payload: any) => {
  return {
    ...state,
    userInfo: payload
  }
}

export const setAuthTokens = (state: any, payload: any) => {
  return {
    ...state,
    tokens: payload
  }
}

export const setMyUserAvatar = (state: any, payload: any) => {
  return {
    ...state,
    userInfo: {
      ...state.userInfo,
      avatar: payload
    }
  }
}

const resetState = () => initialState

const userAuthenticationReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_USER_AUTH_TOKENS:
      return setAuthTokens(state, action.payload)
    case actionTypes.SET_USER_INFO:
      return setUerInfo(state, action.payload)
    case actionTypes.SET_USER_AUTENTICATED:
      return setUserAuthenticated(state, action.payload)
    case actionTypes.SET_USER_TYPE:
      return setUserType(state, action.payload)
    case actionTypes.PERFORM_LOGOUT:
      return resetState()
    case actionTypes.RESET_SCHEDULE:
      return resetState()
    case actionTypes.SET_MY_AVATAR:
      return setMyUserAvatar(state, action.payload)
    default:
      return state
  }
}
export default userAuthenticationReducer
