import * as actionTypes from '../actionTypes/myAvatar'

export const getMyAvatarDetails = (payload: any) => {
  return {
    type: actionTypes.GET_MY_AVATAR_DETAILS,
    payload
  }
}

export const getResetMyAvatarDetails = (payload: any) => {
  return {
    type: actionTypes.GET_MY_AVATAR_DETAILS,
    payload
  }
}

export const modifyMyAvatarDetails = (payload: any) => {
  return {
    type: actionTypes.MODIFY_MY_AVATAR_DETAILS,
    payload
  }
}

export const getAvatarComponentDetails = (payload: any) => {
  return {
    type: actionTypes.GET_AVATAR_COMPONENT_DETAILS,
    payload
  }
}

export const getAllAvatarComponentDetails = (payload: any) => {
  return {
    type: actionTypes.GET_ALL_AVATAR_COMPONENT_DETAILS,
    payload
  }
}
