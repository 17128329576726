import { quizSet } from '@constants/Quiz'
import { parseData, isEqual, updateObject } from '@helpers/miscellaneous'
import {
  IQuizReducerInitialState,
  IQuizSets,
  IQuizPayload,
  IQuizSetPayload,
  ITopicsPayload,
  IOhmTeacherBatchPayload,
  IAddQuizSetPayload,
  IQuizResultsPayload,
  ISubmitFeedbackStatusPayload,
  IQuizesPayload,
  ITopicPayload
} from '@interfaces/stores/Quiz'

import * as actionTypes from '../actionTypes/Quiz'

const initialState: IQuizReducerInitialState = {
  quizSets: [],
  quizSet: {},
  topics: [],
  ohmTeacherBatch: [],
  quizes: [],
  quizQuestions: [],
  isFeedbackSubmittedSuccess: false
}

const setQuizSets = (state: IQuizSets, action: IQuizPayload) => {
  return updateObject(state, {
    quizSets: action.quizSets
  })
}

const updateQuizSets = (state: IQuizSets, action: IQuizSetPayload) => {
  const updatedQuizSets = parseData(state.quizSets)
  const quizSetIndex = state.quizSets.findIndex((item) =>
    isEqual(item.uuid, action.quizSet.uuid)
  )
  if (quizSetIndex > -1) {
    updatedQuizSets[quizSetIndex] = action.quizSet
  }
  return updateObject(state, {
    quizSets: updatedQuizSets
  })
}

const setTopics = (state: IQuizSets, action: ITopicsPayload) => {
  return updateObject(state, {
    topics: action.topics
  })
}

const setOhmTeacherBatch = (
  state: IQuizSets,
  action: IOhmTeacherBatchPayload
) => {
  return updateObject(state, {
    ohmTeacherBatch: action.ohmTeacherBatch
  })
}

const addQuizSet = (state: IQuizSets, action: IAddQuizSetPayload) => {
  const quizSets = parseData(state.quizSets)
  quizSets.unshift(action.set)
  return updateObject(state, {
    quizSets,
    quizSet: action.set
  })
}

const setQuizes = (state: IQuizReducerInitialState, action: IQuizesPayload) => {
  return updateObject(state, {
    quizes: action.quizes
  })
}

const addTopic = (state: IQuizReducerInitialState, action: ITopicPayload) => {
  const topics = parseData(state.topics)
  topics.push(action.topic)
  return updateObject(state, {
    topics
  })
}

const emptyQuizes = (state: IQuizReducerInitialState) => {
  return updateObject(state, {
    quizes: [],
    quizQuestions: []
  })
}

const setQuizResults = (
  state: IQuizReducerInitialState,
  action: IQuizResultsPayload
) => {
  return updateObject(state, {
    quizQuestions: action.quizQuestions
  })
}

const setSubmitFeedbackStatus = (
  state: IQuizReducerInitialState,
  action: ISubmitFeedbackStatusPayload
) => {
  return updateObject(state, {
    isFeedbackSubmittedSuccess: action.status
  })
}

const Quiz = (state = initialState, action: any): IQuizSets => {
  switch (action.type) {
    case actionTypes.SET_QUIZ_SETS:
      return setQuizSets(state, action)
    case actionTypes.UPDATE_QUIZ_SETS:
      return updateQuizSets(state, action)
    case actionTypes.SET_TOPICS:
      return setTopics(state, action)
    case actionTypes.ADD_TOPIC:
      return addTopic(state, action)
    case actionTypes.SET_OHM_TEACHER_BATCH:
      return setOhmTeacherBatch(state, action)
    case actionTypes.ADD_QUIZ_SET:
      return addQuizSet(state, action)
    case actionTypes.SET_QUIZES:
      return setQuizes(state, action)
    case actionTypes.EMPTY_QUIZES:
      return emptyQuizes(state)
    case actionTypes.SET_QUIZ_RESULTS:
      return setQuizResults(state, action)
    case actionTypes.SET_SUBMIT_FEEDBACK_STATUS:
      return setSubmitFeedbackStatus(state, action)
    default:
      return state
  }
}

export default Quiz
